import { useCardsStore } from "../hooks/useCardsStore";
import {
  selectCorrectCards,
  selectIncorrectCards,
  selectNeutralCards,
} from "../hooks/useCardsStore.helpers";
import styles from "./ProgressBar.module.scss";
import cx from "classnames";

export function ProgressBar() {
  const { cardsStore } = useCardsStore();
  const correctCards = selectCorrectCards(cardsStore);
  const incorrectCards = selectIncorrectCards(cardsStore);
  const neutralCards = selectNeutralCards(cardsStore);

  const numCorrect = correctCards.length;
  const numIncorrect = incorrectCards.length;
  const numNeutral = neutralCards.length;

  const total = numCorrect + numIncorrect + numNeutral;

  const percentCorrect = (numCorrect / total) * 100;
  const percentIncorrect = (numIncorrect / total) * 100;
  const percentNeutral = (numNeutral / total) * 100;

  return (
    <div
      className={cx(
        styles.ProgressBar,
        "w-72 h-4 rounded-lg flex overflow-hidden "
      )}
    >
      <div
        style={{ width: `${percentCorrect}%` }}
        className={cx(styles.segment, styles.pololei)}
      />
      <div
        style={{ width: `${percentIncorrect}%` }}
        className={cx(styles.segment, styles.hewa)}
      />
      <div
        style={{ width: `${percentNeutral}%` }}
        className={cx(styles.segment, styles.koe)}
      />
    </div>
  );
}
