import { useRef } from "react";
import styles from "./Resizable.module.scss";

interface ResizableHorizontalProps {
  left: React.ReactNode;
  right: React.ReactNode;
  leftInitialWidth?: string | number;
  onChangeWidth?: (value: number) => void;
}

interface ResizableVerticalProps {
  top: React.ReactNode;
  bottom: React.ReactNode;
  theme?: "light" | "dark";
  topInitialHeight?: string | number;
  onChangeHeight?: (value: number) => void;
}

// https://codefrontend.com/resize-observer-react/#:~:text=Using%20the%20Resize%20Observer%20in%20React&text=Call%20the%20useEffect%20hook%20in,with%20a%20call%20to%20ResizeObserver.

export function ResizableHorizontal(props: ResizableHorizontalProps) {
  const { left, right, onChangeWidth } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);

  const handleRef = useRef<HTMLDivElement>(null);

  const handleResize = (event: MouseEvent) => {
    if (!containerRef.current) return;
    if (!leftRef.current) return;
    if (!rightRef.current) return;

    const containerLeft = containerRef.current.getBoundingClientRect().left;
    const containerWidth = containerRef.current.offsetWidth;

    const mouseLeft = event.clientX;

    const leftMinWidth = 8;
    const rightMinWidth = 14;

    const newWidth = Math.min(
      // don't let left container be smaller than min
      Math.max(mouseLeft - containerLeft, leftMinWidth),
      // don't let left container be bigger than container size. Leave space for right container
      containerWidth - rightMinWidth
    );

    // set new width
    leftRef.current.style.width = `${newWidth}px`;
    onChangeWidth?.(newWidth);
  };

  const handleResizerPointerDown: React.PointerEventHandler<HTMLDivElement> = (
    evt
  ) => {
    evt.preventDefault();

    window.addEventListener("pointermove", handleResize);

    if (handleRef.current) {
      // add styles
      handleRef.current.setPointerCapture(evt.pointerId);
    }

    window.addEventListener(
      "pointerup",
      function () {
        if (handleRef.current) {
          // remove styles
          handleRef.current.releasePointerCapture(evt.pointerId);
        }
        window.removeEventListener("pointermove", handleResize);
      },
      { once: true }
    );
  };

  return (
    <div
      ref={containerRef}
      className={`${styles.Resizable} ${styles.horizontal}`}
    >
      <div // left
        style={{ width: props.leftInitialWidth }}
        className={styles.left}
        ref={leftRef}
      >
        {left}
      </div>
      <div // resizer
        className={styles.resizerHorizontal}
        onPointerDown={handleResizerPointerDown}
      >
        <div className={styles.handle} ref={handleRef} />
      </div>
      <div // right
        className={styles.right}
        ref={rightRef}
      >
        {right}
      </div>
    </div>
  );
}

export function ResizableVertical(props: ResizableVerticalProps) {
  const { top, bottom, onChangeHeight } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  const handleRef = useRef<HTMLDivElement>(null);

  const handleResize = (event: PointerEvent) => {
    if (!containerRef.current) return;
    if (!topRef.current) return;
    if (!bottomRef.current) return;

    const containerTop = containerRef.current.getBoundingClientRect().top;
    const containerHeight = containerRef.current.offsetHeight;

    const mouseTop = event.clientY;

    const topMinHeight = 8;
    const bottomMinHeight = 14;

    const newHeight = Math.min(
      // don't let left container be smaller than min
      Math.max(mouseTop - containerTop, topMinHeight),
      // don't let left container be bigger than container size. Leave space for right container
      containerHeight - bottomMinHeight
    );

    console.log({ newHeight, mouseTop, containerTop });

    // set new height
    topRef.current.style.height = `${newHeight}px`;
    onChangeHeight?.(newHeight);
  };

  const handleResizerPointerDown: React.PointerEventHandler<HTMLDivElement> = (
    evt
  ) => {
    evt.preventDefault();

    window.addEventListener("pointermove", handleResize);

    if (handleRef.current) {
      // add styles
      handleRef.current.setPointerCapture(evt.pointerId);
    }

    window.addEventListener(
      "pointerup",
      function () {
        if (handleRef.current) {
          // remove styles
          handleRef.current.releasePointerCapture(evt.pointerId);
        }
        window.removeEventListener("pointermove", handleResize);
      },
      { once: true }
    );
  };

  return (
    <div
      ref={containerRef}
      className={`${styles.Resizable} ${styles.vertical}`}
    >
      <div // top
        style={{ height: props.topInitialHeight }}
        className={styles.top}
        ref={topRef}
      >
        {top}
      </div>
      <div // resizer
        className={styles.resizerVertical}
        onPointerDown={handleResizerPointerDown}
      >
        <div className={styles.handle} ref={handleRef} />
      </div>
      <div // bottom
        className={styles.bottom}
        ref={bottomRef}
      >
        {bottom}
      </div>
    </div>
  );
}
