import cx from "classnames";
import styles from "./Kakau.module.scss";
import { ResizableHorizontal } from "../shared/Resizable/Resizable";
import { useCardsStore } from "../hooks/useCardsStore";
import {
  changeCurrentDeck,
  createDeck,
  deleteDeck,
  renameDeck,
  selectAllCards,
  selectCurrentDeck,
  selectDecks,
  selectInputText,
} from "../hooks/useCardsStore.helpers";
import { Select } from "../shared/Select/Select";
import { ChangeEvent } from "react";
import { Table } from "./Table";
import { KakauToolbar } from "./KakauToolbar";

export function KakauPage() {
  return (
    <div className={cx(styles.KakauPage)}>
      <KakauToolbar />
      <KakauBody />
    </div>
  );
}

export function KakauBody() {
  const { cardsStore, setCardsStore } = useCardsStore();
  const currentDeck = selectCurrentDeck(cardsStore);

  const input = (
    <div className={"w-full h-full"}>
      <div className={"relative w-full h-full"}>
        {currentDeck?.id ? (
          <Table />
        ) : (
          // <textarea
          //   className={cx(
          //     styles.textArea,
          //     "absolute top-0 left-0 right-0 bottom-0 p-6 resize-none text-xl outline-none rounded-none"
          //   )}
          //   value={inputText}
          //   onChange={(e) => {
          //     const value = e.target.value;
          //     if (!currentDeck?.id) return;
          //     setCardsStore(updateDeckInput(currentDeck?.id, value));
          //   }}
          //   placeholder="Enter text here"
          // />
          <button
            className="rounded border p-1 cursor-pointer m-1"
            onClick={() => {
              setCardsStore(createDeck());
            }}
          >
            New Deck
          </button>
        )}
      </div>
    </div>
  );

  const handleSearchClick = (query: string) => {
    let japaneseRegex =
      /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g;
    const url = (function () {
      if (japaneseRegex.test(query)) {
        return `https://jisho.org/search/${query}`;
      } else {
        return `https://hilo.hawaii.edu/wehe/?q=${query}`;
      }
    })();

    // const url = `https://jisho.org/search/${text}`;
    // const url = `https://hilo.hawaii.edu/wehe/?q=${text}`;
    let params = `width=500,height=9999,left=9999,top=0`;
    const name = "wehe"; // name of the window
    // can't do a popunder unfortunately https://stackoverflow.com/questions/4261784/making-a-window-pop-under-in-chrome
    window.open(url, name, params);
  };

  const cardList = (function () {
    const list = selectAllCards(cardsStore);
    return (
      <div className={cx("overflow-y-scroll h-full", styles.CardList)}>
        <div className={"h-full p-6"}>
          {list.map((item) => {
            return (
              <div className={""}>
                <div className="flex justify-between">
                  <div>
                    <div className={cx(styles.CardAnswer, "cursor-pointer")}>
                      {segmentWords(item.answer).map((segment) => {
                        if (segment.isWordLike) {
                          return (
                            <span
                              onClick={() => {
                                handleSearchClick(segment.segment);
                              }}
                            >
                              {segment.segment}
                            </span>
                          );
                        }
                        return <span>{segment.segment}</span>;
                      })}
                    </div>
                    <div className={cx(styles.CardQuestion, "cursor-pointer")}>
                      {segmentWords(item.question).map((segment) => {
                        if (segment.isWordLike) {
                          return (
                            <span
                              onClick={() => {
                                handleSearchClick(segment.segment);
                              }}
                            >
                              {segment.segment}
                            </span>
                          );
                        }
                        return <span>{segment.segment}</span>;
                      })}
                    </div>
                    {/* <div
                      className={cx(styles.CardAnswer, "cursor-pointer")}
                      onClick={() => {
                        handleSearchClick(item.answer);
                      }}
                    >
                      {item.answer}
                    </div> */}
                    {/* <div
                      className={cx(styles.CardQuestion, "cursor-pointer")}
                      onClick={() => {
                        handleSearchClick(item.question);
                      }}
                    >
                      {item.question}
                    </div> */}
                  </div>
                  {/* <div>
                    <button
                      onClick={() => {
                        handleSearchClick(item.question);
                      }}
                    >
                      GO
                    </button>
                  </div> */}
                </div>
                <hr />
              </div>
            );
          })}
        </div>
      </div>
    );
  })();

  return (
    <div className={cx(styles.KakauBody, "w-full")}>
      <ResizableHorizontal
        left={input}
        right={cardList}
        leftInitialWidth={650}
      />
      {input}
    </div>
  );
}

export function segmentWords(str: string): Intl.SegmentData[] {
  const segmenter = new Intl.Segmenter("haw", { granularity: "word" });

  // const words = Array.from(segmenter.segment(str), (s) => s.segment);
  return Array.from(segmenter.segment(str) ?? []);
}
