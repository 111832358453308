import cx from "classnames";
import styles from "./Hiona.module.scss";
import { useState } from "react";
import { useGlobal } from "../hooks/useGlobal";

export type FontStyle = "normal" | "old" | "rounded";

export function Hiona() {
  const { fontStyle, setFontStyle } = useGlobal();
  return (
    <div className={cx(styles.Hiona, "w-full")}>
      <div className="max-w-screen-md mx-auto p-8">
        {/* <h1 className={cx(styles.font, "font-bold text-4xl")}>Hiona</h1> */}
        {/* <Section>
          <Header title="Loulou" />
        </Section> */}
        <Section>
          <Header title="Leka" />
          <Option
            text="maʻamau"
            className={styles.fontNormal}
            onClick={() => {
              setFontStyle("normal");
            }}
            selected={fontStyle === "normal"}
          />
          <Option
            text="omole"
            className={styles.fontRound}
            onClick={() => {
              setFontStyle("rounded");
            }}
            selected={fontStyle === "rounded"}
          />
          <Option
            text="kahiko"
            className={styles.fontOld}
            onClick={() => {
              setFontStyle("old");
            }}
            selected={fontStyle === "old"}
          />
        </Section>
      </div>
    </div>
  );
}

function Option(props: {
  selected: boolean;
  text: string;
  className: string;
  onClick: () => void;
}) {
  const { text, className, onClick, selected } = props;
  return (
    <div
      onClick={() => onClick()}
      className={cx(
        "border rounded-md mb-4 p-4 text-4xl cursor-pointer",
        className,
        styles.option,
        { [styles.selected]: selected }
      )}
    >
      {text}
    </div>
  );
}

function Section({ children }: { children: React.ReactNode }) {
  return (
    <div className={cx("border rounded-md p-3 mb-8", styles.section)}>
      {children}
    </div>
  );
}

function Header({ title }: { title: string }) {
  return <h1 className={"font-bold text-3xl mb-8"}>{title}</h1>;
}

// A → B
// -----
// A → C
// -----
// B → C
// -----
// A → BC
// ------
// AB → C
// ------
// AC → B

// [Swap]
