export function randomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function shuffle<T>(array: T[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export function onlyWhiteSpace(str: string) {
  return /^\\s*$/.test(str);
}

export function notNullOrUndefined<T>(item: T | null | undefined): item is T {
  if (item == null) return false;
  return true;
}

export function getRandomItem<T>(arr: T[]) {
  const maxIdx = arr.length - 1;
  const randIdx = randomInteger(0, maxIdx);
  return arr[randIdx];
}

export function getRandomItemIdx<T>(arr: T[]) {
  if (arr.length === 0) return null;
  const maxIdx = arr.length - 1;
  const randIdx = randomInteger(0, maxIdx);
  return randIdx;
}
