import { v4 } from "uuid";
import {
  getRandomItem,
  getRandomItemIdx,
  notNullOrUndefined,
  onlyWhiteSpace,
} from "../helpers/helpers";

export type Card = {
  idx: number;
  question: string;
  answer: string;
};

type CardDeck = {
  id: string;
  name: string;
  input: string;
  correct: number[];
  incorrect: number[];
  currentCorrectCard: number | null;
  currentIncorrectCard: number | null;
  currentNeutralCard: number | null;
};

export type CardStore = {
  currentDeckId: string | null;
  decks: CardDeck[];
};

type ActionCardStore = (state: CardStore) => typeof state;

function initialCardDeck(): CardDeck {
  return {
    id: v4(),
    name: "Untitled",
    input: "",
    correct: [],
    incorrect: [],
    currentCorrectCard: null,
    currentIncorrectCard: null,
    currentNeutralCard: null,
  };
}

export const initialCardStore: CardStore = {
  currentDeckId: null,
  decks: [initialCardDeck()],
};

export function createDeck(): ActionCardStore {
  return function (state: CardStore) {
    const newDeck = initialCardDeck();
    return {
      ...state,
      currentDeckId: newDeck.id,
      decks: [...state.decks, newDeck],
    };
  };
}

export function deleteDeck(id: string): ActionCardStore {
  return function (state: CardStore) {
    const newDecks = state.decks.filter((item) => item.id !== id);
    return {
      ...state,
      currentDeckId: newDecks[0]?.id ?? null,
      decks: newDecks,
    };
  };
}

export function renameDeck(id: string, name: string): ActionCardStore {
  return function (state: CardStore) {
    return {
      ...state,
      decks: state.decks.map((item) => {
        if (item.id === id) {
          return { ...item, name };
        }
        return item;
      }),
    };
  };
}

export function changeCurrentDeck(id: string): ActionCardStore {
  return function (state: CardStore) {
    // bail if not found
    if (!state.decks.some((deck) => deck.id === id)) {
      return state;
    }
    return {
      ...state,
      currentDeckId: id,
    };
  };
}

export function updateDeckInput(id: string, input: string): ActionCardStore {
  return function (state: CardStore) {
    const cards = parseCardDeckInput(input);
    return {
      ...state,
      decks: state.decks.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            input,
            // reset the correct/incorrect arrays
            correct: [],
            incorrect: [],
            currentCorrectCard: null,
            currentIncorrectCard: null,
            currentNeutralCard: getRandomItemIdx(cards),
          };
        }
        return item;
      }),
    };
  };
}

export function voteItemCorrect(itemIdx: number): ActionCardStore {
  return function (state: CardStore) {
    return {
      ...state,
      decks: state.decks.map((deck) => {
        if (deck.id === state.currentDeckId) {
          // add to correct
          const correct = Array.from(new Set([...deck.correct, itemIdx]));
          // remove from incorrect if present
          const incorrect = deck.incorrect.filter(
            (cardIdx) => cardIdx !== itemIdx
          );
          const neutral = parseCardDeckInput(deck.input)
            .map((_, idx) => idx)
            .filter(
              (idx) => !(correct.includes(idx) || incorrect.includes(idx))
            );
          return {
            ...deck,
            correct: correct,
            incorrect: incorrect,
            currentCorrectCard: getRandomItem(correct) ?? null,
            currentIncorrectCard: getRandomItem(incorrect) ?? null,
            currentNeutralCard: getRandomItem(neutral) ?? null,
          };
        }
        return deck;
      }),
    };
  };
}

export function voteItemIncorrect(itemIdx: number): ActionCardStore {
  return function (state: CardStore) {
    return {
      ...state,
      decks: state.decks.map((deck) => {
        if (deck.id === state.currentDeckId) {
          // remove from correct if present
          const correct = deck.correct.filter((cardIdx) => cardIdx !== itemIdx);
          // add to incorrect
          const incorrect = Array.from(new Set([...deck.incorrect, itemIdx]));
          const neutral = parseCardDeckInput(deck.input)
            .map((_, idx) => idx)
            .filter(
              (idx) => !(correct.includes(idx) || incorrect.includes(idx))
            );
          return {
            ...deck,
            correct: correct,
            incorrect: incorrect,
            currentCorrectCard: getRandomItem(correct) ?? null,
            currentIncorrectCard: getRandomItem(incorrect) ?? null,
            currentNeutralCard: getRandomItem(neutral) ?? null,
          };
        }
        return deck;
      }),
    };
  };
}

export function resetDeck(deckId: string): ActionCardStore {
  return function (state: CardStore) {
    return {
      ...state,
      decks: state.decks.map((deck) => {
        if (deck.id === deckId) {
          const currentNeutralCard = getRandomItem(
            parseCardDeckInput(deck.input).map((_, idx) => idx)
          );
          //   return cards.find;
          return {
            ...deck,
            correct: [],
            incorrect: [],
            currentCorrectCard: null,
            currentIncorrectCard: null,
            currentNeutralCard: currentNeutralCard,
          };
        }
        return deck;
      }),
    };
  };
}

export function selectDecks(state: CardStore) {
  return state.decks;
}

export function selectCurrentDeck(state: CardStore) {
  const deck = state.decks.find((deck) => deck.id === state.currentDeckId);
  return deck;
}

export function selectInputText(state: CardStore) {
  const deck = state.decks.find((deck) => deck.id === state.currentDeckId);
  if (!deck) return "";
  return deck.input;
}

export function selectCorrectCards(state: CardStore) {
  const deck = state.decks.find((deck) => deck.id === state.currentDeckId);
  if (!deck) return [];
  const cards = parseCardDeckInput(deck.input);
  return cards.filter((card) => deck.correct.includes(card.idx));
}

export function selectIncorrectCards(state: CardStore) {
  const deck = state.decks.find((deck) => deck.id === state.currentDeckId);
  if (!deck) return [];
  const cards = parseCardDeckInput(deck.input);
  return cards.filter((card) => deck.incorrect.includes(card.idx));
}

export function selectNeutralCards(state: CardStore) {
  const deck = state.decks.find((deck) => deck.id === state.currentDeckId);
  if (!deck) return [];
  const cards = parseCardDeckInput(deck.input);
  return cards.filter((card) => {
    return (
      // cards that arenʻt found in either array (correct or incorrect)
      !deck.correct.includes(card.idx) && !deck.incorrect.includes(card.idx)
    );
  });
}

export function selectCurrentCorrectCard(state: CardStore) {
  const deck = state.decks.find((deck) => deck.id === state.currentDeckId);
  if (!deck) return null;
  const cards = parseCardDeckInput(deck.input);
  if (deck.currentCorrectCard == null) return null;
  return cards[deck.currentCorrectCard] ?? null;
}

export function selectCurrentIncorrectCard(state: CardStore) {
  const deck = state.decks.find((deck) => deck.id === state.currentDeckId);
  if (!deck) return null;
  const cards = parseCardDeckInput(deck.input);
  if (deck.currentIncorrectCard == null) return null;
  return cards[deck.currentIncorrectCard] ?? null;
}

export function selectCurrentNeutralCard(state: CardStore) {
  const deck = state.decks.find((deck) => deck.id === state.currentDeckId);
  if (!deck) return null;
  const cards = parseCardDeckInput(deck.input);
  if (deck.currentNeutralCard == null) return null;
  return cards[deck.currentNeutralCard] ?? null;
}

export function selectAllCards(state: CardStore) {
  const deck = state.decks.find((deck) => deck.id === state.currentDeckId);
  if (!deck) return [];
  const cards = parseCardDeckInput(deck.input);
  return cards;
}

export const ROW_DELIMITER = "\n";
export const COLUMN_DELIMITER = "\t";

function parseCardDeckInput(text: string): Card[] {
  const lines = text.split(ROW_DELIMITER);

  const parsed = lines
    .map((line: string) => {
      const [question, answer] = line.split(COLUMN_DELIMITER);
      if (!question || !answer) return null;
      if (onlyWhiteSpace(line)) return null;
      return {
        question,
        answer,
      };
    })

    .filter(notNullOrUndefined)

    .map((item, idx) => {
      return {
        ...item,
        idx: idx,
      };
    });

  return parsed;
}
