// import "./baseline.scss";
import 'handsontable/dist/handsontable.full.min.css';
import { Nav } from "./pages/Nav/Nav";
import { useGlobal } from "./hooks/useGlobal";
import { ResizableHorizontal } from "./shared/Resizable/Resizable";
import { useTheme } from "./hooks/useTheme";
import { Outlet } from "react-router-dom";
import cx from "classnames";
import styles from "./App.module.scss";
import { useState } from "react";

export function App() {
  useTheme();

  return (
    <div className={"h-screen overflow-hidden flex flex-col"}>
      <div className={"overflow-hidden flex h-full"}>
        <div className={"h-full w-main w-nav"}>
          <Nav />
        </div>
        <Outlet />
      </div>
    </div>
  );
}

export function Main() {
  const { theme } = useGlobal();
  // const [initialWidthResizerHorizontal, setInitialWidthResizerHorizontal] =
  //   useLocalStorage(LocalStorageKey.HULI_MAIN_RESIZER_HORIZONTAL, 480);
  const [initialWidthResizerHorizontal, setInitialWidthResizerHorizontal] =
    useState(480);
  return (
    <div className={cx(styles.widthMain, "h-full")}>
      <ResizableHorizontal
        left={<Outlet />}
        right={<>RIGHT</>}
        leftInitialWidth={initialWidthResizerHorizontal}
        onChangeWidth={(val) => setInitialWidthResizerHorizontal(val)}
      />
    </div>
  );
}
