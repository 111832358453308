import React from "react";
import { LocalStorageKey, useLocalStorage } from "./useLocalStorage";
import { FontStyle } from "../components/Hiona";
// https://kentcdodds.com/blog/how-to-use-react-context-effectively
const GlobalContext = React.createContext<GlobalContextValue | undefined>(
  undefined
);

function useGlobalContext() {
  const [theme, setTheme] = useLocalStorage<"light" | "dark">(
    LocalStorageKey.HAAWINA_THEME,
    "light"
  );
  const [fontStyle, setFontStyle] = useLocalStorage<FontStyle>(
    LocalStorageKey.HAAWINA_FONT,
    "normal"
  );

  return {
    theme,
    setTheme,
    fontStyle,
    setFontStyle,
  };
}

export function GlobalProvider({ children }: { children: React.ReactNode }) {
  const value = useGlobalContext();

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}

type GlobalContextValue = ReturnType<typeof useGlobalContext>;

export function useGlobal() {
  const context = React.useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobal must be used within a GlobalProvider");
  }
  return context;
}
