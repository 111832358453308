import { useGlobal } from "../hooks/useGlobal";
import styles from "./PlayerToolbar.module.scss";
import cx from "classnames";

import { SvgMoon } from "../assets/svg/moon";
import { SvgSun } from "../assets/svg/sun";
import {
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { SvgRadioCheck } from "../assets/svg/radio-check";
import { SvgCheckO } from "../assets/svg/check-o";
import { SvgCloseO } from "../assets/svg/close-o";
import { useCardsStore } from "../hooks/useCardsStore";
import {
  resetDeck,
  selectCorrectCards,
  selectCurrentDeck,
  selectIncorrectCards,
  selectNeutralCards,
} from "../hooks/useCardsStore.helpers";
import {
  PATH_HELE_POLOLEI,
  PATH_HELE_HEWA,
  PATH_HELE_KOE,
} from "../pages/Nav/Nav";
import { ProgressBar } from "./ProgressBar";
import { SvgRedo } from "../assets/svg/redo";
import { SvgSwap } from "../assets/svg/swap";
import { usePlayer } from "../hooks/usePlayer";
import { IconButton } from "../shared/IconButton/IconButton";

export function PlayerToolbar() {
  const { setTheme, theme } = useGlobal();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { cardsStore } = useCardsStore();
  // const { currentDeckId, drawCurrentCard } = usePlayer();
  const correctCards = selectCorrectCards(cardsStore);
  const incorrectCards = selectIncorrectCards(cardsStore);
  const neutralcorrectCards = selectNeutralCards(cardsStore);

  return (
    <div
      className={cx(
        styles.Toolbar,
        "h-[72px] min-h-[72px] w-full flex justify-between"
      )}
    >
      <div className="flex">
        <PlayerToolbarItem
          name="Pololei"
          subscript={`${correctCards.length}`}
          onClick={() => {
            navigate(PATH_HELE_POLOLEI);
            // drawCurrentCard("correct");
          }}
          icon={<SvgCheckO className={"h-[40px]"} />}
          isSelected={pathname === PATH_HELE_POLOLEI}
          className={styles.pololei}
        />

        <PlayerToolbarItem
          name="Hewa"
          subscript={`${incorrectCards.length}`}
          onClick={() => {
            navigate(PATH_HELE_HEWA);
            // drawCurrentCard("incorrect");
          }}
          icon={<SvgCloseO className={"h-[40px]"} />}
          isSelected={pathname === PATH_HELE_HEWA}
          className={styles.hewa}
        />
        <PlayerToolbarItem
          name="Koe"
          subscript={`${neutralcorrectCards.length}`}
          onClick={() => {
            navigate(PATH_HELE_KOE);
            // drawCurrentCard("neutral");
          }}
          icon={<SvgRadioCheck className={"h-[40px]"} />}
          isSelected={pathname === PATH_HELE_KOE}
          className={styles.koe}
        />
      </div>
      <div className={"flex items-center px-6"}>
        <ProgressBar />
        <div className={"mx-1"}>
          <ResetButton />
        </div>
        <div className={"mx-1"}>
          <SwapButton />
        </div>
      </div>
    </div>
  );
}

export function PlayerToolbarItem(props: {
  name: string;
  onClick: () => void;
  icon?: React.ReactNode;
  isSelected?: boolean;
  subscript?: string;
  className?: string;
}) {
  const { onClick, icon, isSelected, name, subscript, className } = props;
  const { theme } = useGlobal();
  return (
    <div
      className={cx(
        className,
        styles.ToolbarItem,
        "h-full w-[180px] font-bold text-lg cursor-pointer flex items-center justify-between px-2 gap-3 select-none",
        {
          //   "ml-[-1px]": isSelected,
          //   "ml-[2px]": !isSelected,
          [styles.selected]: isSelected,
        }
      )}
      onClick={onClick}
      data-theme={theme}
    >
      <span className={cx(styles.IconContainer, "p-1 rounded-full relative")}>
        {icon}
      </span>
      <span className={"grow text-center"}>
        {name}
        <span
          className={cx("grow text-center rounded-full px-2", styles.subscript)}
        >
          {`(${subscript})`}
        </span>
      </span>
    </div>
  );
}

function ResetButton() {
  const { cardsStore, setCardsStore } = useCardsStore();
  const currentDeck = selectCurrentDeck(cardsStore);
  const handleClick = () => {
    if (!currentDeck?.id) return;
    setCardsStore(resetDeck(currentDeck?.id));
  };
  return (
    <IconButton onClick={handleClick}>
      <SvgRedo className={"h-[28px] inline-flex items-center align-middle"} />
    </IconButton>
  );
}

function SwapButton() {
  const { setIsSwapped } = usePlayer();

  const handleClick = () => {
    setIsSwapped((s) => !s);
  };

  return (
    <IconButton onClick={handleClick}>
      <SvgSwap className={"h-[36px] inline-flex items-center align-middle"} />
    </IconButton>
  );
}
