import { SvgMoon } from "../../assets/svg/moon";
import { SvgSun } from "../../assets/svg/sun";
import { useGlobal } from "../../hooks/useGlobal";
import {
  Navigate,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import cx from "classnames";
import { App } from "../../App";
import appStyles from "../../App.module.scss";
import styles from "./Nav.module.scss";
import { SvgPlayListAdd } from "../../assets/svg/play-list-add";
import { PlayerPage } from "../../components/Player";
import { KakauPage } from "../../components/Kakau";
import { useCardsStore } from "../../hooks/useCardsStore";
import {
  selectCorrectCards,
  selectIncorrectCards,
  selectNeutralCards,
} from "../../hooks/useCardsStore.helpers";
import { Providers } from "../..";
import { SvgPlayButtonO } from "../../assets/svg/play-button-o";
import { Hiona } from "../../components/Hiona";
import { SvgOptions } from "../../assets/svg/options";

export const PATH_KAKAU = "/kakau";
export const PATH_HELE = "/hele";
export const PATH_HELE_POLOLEI = "/hele/pololei";
export const PATH_HELE_HEWA = "/hele/hewa";
export const PATH_HELE_KOE = "/hele/koe";
export const PATH_HIONA = "/hiona";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={PATH_HELE_KOE} replace />,
    errorElement: <Navigate to={PATH_HELE_KOE} replace />,
  },
  {
    path: PATH_HELE,
    element: (
      <Providers>
        <App />
      </Providers>
    ),
    errorElement: <Navigate to={PATH_HELE_KOE} replace />,
    // loader: rootLoader,
    children: [
      {
        path: PATH_HELE_POLOLEI,
        element: <PlayerPage cardView="correct" />,
      },
      {
        path: PATH_HELE_HEWA,
        element: <PlayerPage cardView="incorrect" />,
      },
      {
        path: PATH_HELE_KOE,
        element: <PlayerPage cardView="neutral" />,
      },
    ],
  },
  {
    path: PATH_KAKAU,
    element: (
      <Providers>
        <App />
      </Providers>
    ),
    errorElement: <Navigate to={PATH_HELE_KOE} replace />,
    children: [
      {
        path: PATH_KAKAU,
        element: <KakauPage />,
      },
    ],
  },
  {
    path: PATH_HIONA,
    element: (
      <Providers>
        <App />
      </Providers>
    ),
    errorElement: <Navigate to={PATH_HELE_KOE} replace />,
    children: [
      {
        path: PATH_HIONA,
        element: <Hiona />,
      },
    ],
  },
]);

export function Nav() {
  const { setTheme, theme } = useGlobal();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { cardsStore } = useCardsStore();
  // const { currentDeckId, drawCurrentCard } = usePlayer();
  const correctCards = selectCorrectCards(cardsStore);
  const incorrectCards = selectIncorrectCards(cardsStore);
  const neutralcorrectCards = selectNeutralCards(cardsStore);

  return (
    <div
      className={cx(
        styles.Nav,
        appStyles.widthNav,
        "flex flex-col justify-between gap-2 h-[calc(100%_+_2px)] border mt-[-1px]",
        "pt-4 pb-10"
      )}
      data-theme={theme}
    >
      <div className={"flex flex-col gap-2"}>
        <NavItem
          name={"Hele"}
          onClick={() => {
            navigate(PATH_HELE_KOE);
            // drawCurrentCard("neutral");
          }}
          icon={<SvgPlayButtonO className={"h-[40px]"} />}
          isSelected={
            pathname === PATH_HELE_KOE ||
            pathname === PATH_HELE_HEWA ||
            pathname === PATH_HELE_POLOLEI
          }
        />
        <NavItem
          name={"Hoʻokomo"}
          onClick={() => {
            navigate(PATH_KAKAU);
          }}
          icon={<SvgPlayListAdd className={"h-[40px]"} />}
          isSelected={pathname === PATH_KAKAU}
        />

        {/* <NavItem
          name={`${correctCards.length}`}
          onClick={() => {
            navigate(PATH_POLOLEI);
            // drawCurrentCard("correct");
          }}
          icon={<SvgCheckO className={"h-[30px]"} />}
          isSelected={pathname === PATH_POLOLEI}
        />
        <NavItem
          name={`${incorrectCards.length}`}
          onClick={() => {
            navigate(PATH_HEWA);
            // drawCurrentCard("incorrect");
          }}
          icon={<SvgCloseO className={"h-[30px]"} />}
          isSelected={pathname === PATH_HEWA}
        />
        */}
      </div>
      <div className={"flex flex-col items-center"}>
        <NavItem
          name={"Hiona"}
          onClick={() => {
            navigate(PATH_HIONA);
          }}
          icon={<SvgOptions className={"h-[36px]"} />}
          isSelected={pathname === PATH_HIONA}
        />
        <ThemeButton />
        {/* <NavItem
          name={"Theme"}
          onClick={() => {
            setTheme((theme) => (theme === "light" ? "dark" : "light"));
          }}
          icon={
            theme === "light" ? (
              <SvgMoon className={"h-[30px]"} />
            ) : (
              <SvgSun className={"h-[30px]"} />
            )
          }
        /> */}
      </div>
    </div>
  );
}

export function NavItem(props: {
  name: string;
  onClick: () => void;
  icon?: React.ReactNode;
  isSelected?: boolean;
}) {
  const { onClick, icon, isSelected, name } = props;
  const { theme } = useGlobal();
  return (
    <div
      className={cx(
        styles.NavItem,
        "h-[80px] cursor-pointer flex flex-col items-center justify-center text-sm font-bold",
        {
          // "ml-[-1px] border-l-[3px]": isSelected,
          // "ml-[2px]": !isSelected,
          [styles.selected]: isSelected,
        }
      )}
      onClick={onClick}
      data-theme={theme}
    >
      <span
        className={cx(
          styles.IconContainer,
          {
            [styles.selected]: isSelected,
          },
          "py-1 px-4 rounded-2xl mb-1"
        )}
      >
        {icon}
      </span>
      <span className={""}>{name}</span>
    </div>
  );
}

function ThemeButton() {
  const { setTheme, theme } = useGlobal();
  return (
    <div className={cx(styles.ThemeButton, "mt-4")}>
      <button
        className="w-[56px] h-[56px] rounded-full"
        onClick={() => {
          setTheme((theme) => (theme === "light" ? "dark" : "light"));
        }}
      >
        <span>
          {theme === "light" ? (
            <SvgMoon
              className={"h-[32px] inline-flex items-center align-middle"}
            />
          ) : (
            <SvgSun
              className={"h-[32px] inline-flex items-center align-middle"}
            />
          )}
        </span>
      </button>
    </div>
  );
}
