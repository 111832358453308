import React from "react";
import { LocalStorageKey, useLocalStorage } from "./useLocalStorage";
import { v4 } from "uuid";
import { CardStore, initialCardStore } from "./useCardsStore.helpers";

// https://kentcdodds.com/blog/how-to-use-react-context-effectively
const CardsStoreContext = React.createContext<
  CardsStoreContextValue | undefined
>(undefined);

function useCardsStoreContext() {
  const [cardsStore, setCardsStore] = useLocalStorage<CardStore>(
    LocalStorageKey.HAAWINA_CARD_STORE,
    initialCardStore
  );

  return {
    cardsStore,
    setCardsStore,
  };
}

export function CardsStoreProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const value = useCardsStoreContext();

  return (
    <CardsStoreContext.Provider value={value}>
      {children}
    </CardsStoreContext.Provider>
  );
}

type CardsStoreContextValue = ReturnType<typeof useCardsStoreContext>;

export function useCardsStore() {
  const context = React.useContext(CardsStoreContext);
  if (context === undefined) {
    throw new Error("useCardsStore must be used within a CardsStoreProvider");
  }
  return context;
}
