import cx from "classnames";
import styles from "./KakauToolbar.module.scss";
import { useGlobal } from "../hooks/useGlobal";

import { useLocation, useNavigate } from "react-router-dom";
import { useCardsStore } from "../hooks/useCardsStore";
import {
  changeCurrentDeck,
  createDeck,
  deleteDeck,
  renameDeck,
  selectCurrentDeck,
  selectDecks,
  selectInputText,
} from "../hooks/useCardsStore.helpers";

import { ChangeEvent, useState } from "react";
import { IconButton } from "../shared/IconButton/IconButton";
import { SvgTrash } from "../assets/svg/trash";
import { SvgAdd } from "../assets/svg/add";
import { SvgFolder } from "../assets/svg/folder";

export function KakauToolbar() {
  const { cardsStore, setCardsStore } = useCardsStore();
  const currentDeck = selectCurrentDeck(cardsStore);

  const { setTheme, theme } = useGlobal();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  // const { currentDeckId, drawCurrentCard } = usePlayer();

  const inputText = selectInputText(cardsStore);

  const decks = selectDecks(cardsStore);

  const handleCurrentDeckChange = (deckId: string) => {
    setCardsStore(changeCurrentDeck(deckId));
  };

  const handleDeckNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!currentDeck?.id) return;
    setCardsStore(renameDeck(currentDeck?.id, value));
  };

  const handleBlur = () => {
    if (!currentDeck?.id) return;
    if (!currentDeck?.name) {
      setCardsStore(renameDeck(currentDeck?.id, "Untitled"));
    }
  };

  return (
    <div
      className={cx(styles.KakauToolbar, "h-[72px] min-h-[72px] w-full flex")}
    >
      <div className={cx(styles.Toolbar, "h-full w-full flex justify-between")}>
        <div className="self-center p-6">
          {currentDeck?.id && (
            <input
              type="text"
              value={currentDeck?.name}
              onChange={handleDeckNameChange}
              className={cx(styles.titleInput, "text-2xl")}
              onBlur={handleBlur}
            />
          )}

          {/* <Select
            label={"Select..."}
            value={currentDeck?.id ?? ""}
            onChange={handleCurrentDeckChange}
          >
            {decks.map((item) => {
              return <option value={item.id}>{item.name}</option>;
            })}
          </Select>
          <button
            className="rounded border p-1 cursor-pointer m-1"
            onClick={() => {
              setCardsStore(createDeck());
            }}
          >
            New Deck
          </button>
          <button
            className="rounded border p-1 cursor-pointer m-1"
            onClick={() => {
              if (!currentDeck?.id) return;
              setCardsStore(deleteDeck(currentDeck?.id));
            }}
          >
            Delete
          </button> */}
        </div>
        <div className="flex items-center px-6">
          <div className="mx-1">
            <NewButton />
          </div>
          <div className="mx-1">
            <DeleteButton />
          </div>
          <div className="mx-1">
            <OpenButton />
          </div>
        </div>
      </div>
    </div>
  );
}

function DeleteButton() {
  const { cardsStore, setCardsStore } = useCardsStore();
  const currentDeck = selectCurrentDeck(cardsStore);

  const handleClick = () => {
    if (!currentDeck?.id) return;
    setCardsStore(deleteDeck(currentDeck?.id));
  };

  return (
    <IconButton onClick={handleClick}>
      <SvgTrash className={"h-[30px] inline-flex items-center align-middle"} />
    </IconButton>
  );
}

function NewButton() {
  const { setCardsStore } = useCardsStore();

  const handleClick = () => {
    setCardsStore(createDeck());
  };

  return (
    <IconButton onClick={handleClick}>
      <SvgAdd className={"h-[32px] inline-flex items-center align-middle"} />
    </IconButton>
  );
}

function OpenButton() {
  const { cardsStore, setCardsStore } = useCardsStore();
  const decks = selectDecks(cardsStore);

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen((s) => !s);
  };

  const handleCurrentDeckChange = (deckId: string) => {
    setCardsStore(changeCurrentDeck(deckId));
    setIsOpen(false);
  };

  const menu = (
    <div
      className={cx(
        styles.OpenDropdown,
        "top-0 absolute right-0 border w-[300px] h-[500px] mt-20 mr-8 rounded-lg z-1 overflow-hidden"
      )}
    >
      <div className="flex flex-col h-full">
        <div className={cx("flex-0 pt-3 pb-1 px-3", styles.header)}>
          <h1 className="mb-2 text-xl font-bold">Decks</h1>
        </div>
        <div className="flex-1 overflow-y-scroll p-2">
          {decks.map((item) => {
            return (
              <div
                className={cx(styles.item, "p-1 cursor-pointer")}
                onClick={() => handleCurrentDeckChange(item.id)}
              >
                {item.name}
              </div>
            );
          })}
        </div>
        {/* <div className="flex-0">Footer</div> */}
      </div>
    </div>
  );

  return (
    <div className="relative">
      <IconButton onClick={handleClick}>
        <SvgFolder
          className={"h-[28px] inline-flex items-center align-middle"}
        />
      </IconButton>
      {isOpen && menu}
    </div>
  );
}
