import React, { useState } from "react";
// import "./Player.css";
import { usePlayer } from "../hooks/usePlayer";
// import { useHotkeysContext } from "../hooks/HotkeyContext";
import cx from "classnames";
import styles from "./Player.module.scss";
import { Card } from "../hooks/useCardsStore.helpers";
import { useGlobal } from "../hooks/useGlobal";

export function Question({ question }: { question: string }) {
  const { fontStyle } = useGlobal();
  // const { currentOptions } = useMultipleChoicePlayerContext();
  // const { isMetaKeyDown } = useHotkeysContext();
  const [isLink, setIsLink] = useState(false);
  // useEffect(() => {
  //   if (!isMetaKeyDown) {
  //     setIsLink(false);
  //   }
  // }, [isMetaKeyDown]);

  // early returns
  // if (currentOptions == null) return <></>;
  // const first = currentOptions[0];
  // if (first == null) return <></>;
  // const text = first.data.question;

  const handleMakeLink = () => {
    // if (isMetaKeyDown) {
    //   setIsLink(true);
    // }
  };
  const handleClearLink = () => {
    setIsLink(false);
  };

  const handleLinkClick = () => {
    const url = `https://www.youtube.com/results?search_query=${question}`;
    // const url = `https://jisho.org/search/${text}`;
    // const url = `https://hilo.hawaii.edu/wehe/?q=${text}`;
    let params = `width=500,height=9999,left=9999,top=0`;
    const name = "wehe"; // name of the window
    // can't do a popunder unfortunately https://stackoverflow.com/questions/4261784/making-a-window-pop-under-in-chrome
    window.open(url, name, params);
  };

  return (
    <div className={"questionContainer"}>
      {isLink ? (
        <h1
          onMouseMove={handleMakeLink}
          onMouseEnter={handleMakeLink}
          onMouseLeave={handleClearLink}
          className={cx("questionLink")}
        >
          <span onClick={handleLinkClick} className={cx("questionLink")}>
            {question}
          </span>
        </h1>
      ) : (
        <h1
          onMouseMove={handleMakeLink}
          onMouseEnter={handleMakeLink}
          onMouseLeave={handleClearLink}
          className={cx(styles.question, {
            [styles.fontNormal]: fontStyle === "normal",
            [styles.fontRound]: fontStyle === "rounded",
            [styles.fontOld]: fontStyle === "old",
          })}
        >
          {question}
        </h1>
      )}
      {/* {isMetaKeyDown ? "YES" : "NO"} */}
    </div>
  );
}
