import HotTable, { HotTableProps } from "@handsontable/react";
import styles from "./Table.module.scss";
import cx from "classnames";
import { useCardsStore } from "../hooks/useCardsStore";
import {
  COLUMN_DELIMITER,
  ROW_DELIMITER,
  selectCurrentDeck,
  selectInputText,
  updateDeckInput,
} from "../hooks/useCardsStore.helpers";
import { Ref, useMemo, useRef } from "react";
import HotTableClass from "@handsontable/react/hotTableClass";

function transformInputText(text: string): string[][] {
  const lines = text.split(ROW_DELIMITER);

  const parsed = lines.map((line: string) => {
    const [a, b, c] = line.split(COLUMN_DELIMITER);
    return [a, b, c];
  });

  return parsed;
}

function transformGridData(data: string[][]): string {
  return data
    .map((rowColumns) => {
      return rowColumns.join(COLUMN_DELIMITER);
    })
    .join(ROW_DELIMITER);
}

// function applyChanges(){
//     afterChange={(changes, source) => {
//         changes?.forEach(([row, prop, oldValue, newValue]) => {
//           // Some logic...
//         });
//       }}
// }

//   value={inputText}
//   onChange={(e) => {
//     const value = e.target.value;
//     if (!currentDeck?.id) return;
//     setCardsStore(updateDeckInput(currentDeck?.id, value));
//   }}
export function Table() {
  const gridRef = useRef<HotTableClass | null>(null);
  const { cardsStore, setCardsStore } = useCardsStore();
  const inputText = selectInputText(cardsStore);
  const currentDeck = selectCurrentDeck(cardsStore);

  const props: HotTableProps = {};
  //   props.

  const data = useMemo(() => transformInputText(inputText), [inputText]);

  return (
    <div className={cx(styles.Table, "w-full h-full")}>
      <HotTable
        ref={gridRef}
        data={data}
        rowHeaders={true}
        colHeaders={true}
        afterChange={(changes, source) => {
          if (source === "updateData") {
            // importantly prevents an infinite loop after store is updated
            return;
          }
          if (!gridRef?.current) return;
          const allData = gridRef.current.hotInstance?.getData();
          if (!allData) return;
          const value = transformGridData(allData);
          if (!currentDeck?.id) return;
          setCardsStore(updateDeckInput(currentDeck?.id, value));
        }}
        // height="auto"
        stretchH={"all"}
        // stretchH={"last"}
        colWidths={["33%", "33%", "33%"]}
        // manualColumnResize={true}
        height={"100%"}
        width={"100%"}
        style={{
          zIndex: 0,
        }}
        autoWrapRow={true}
        autoWrapCol={true}
        licenseKey="non-commercial-and-evaluation" // for non-commercial use only
      />
    </div>
  );
}
