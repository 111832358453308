import cx from "classnames";
import styles from "./IconButton.module.scss";

interface IconButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

export function IconButton(props: IconButtonProps) {
  const { children, onClick } = props;
  return (
    <div className={cx(styles.IconButton)}>
      <button className="w-[48px] h-[48px] rounded-full" onClick={onClick}>
        <span className={cx(styles.redo)}>{children}</span>
      </button>
    </div>
  );
}
