import { createContext, useContext, useState } from "react";
import { useLocation, Location } from "react-router-dom";
import {
  selectCorrectCards,
  selectCurrentCorrectCard,
  selectCurrentIncorrectCard,
  selectCurrentNeutralCard,
  selectIncorrectCards,
  selectNeutralCards,
} from "./useCardsStore.helpers";
import { getRandomItem } from "../helpers/helpers";
import { useCardsStore } from "./useCardsStore";

export type Progress = "ready" | "success" | "failure";

// type CurrentCard = {
//   idx: number;
//   question: string;
//   answer: string;
// };

function usePlayerContext() {
  const location = useLocation();
  const [progress, setProgress] = useState<Progress>("ready");
  const { cardsStore } = useCardsStore();

  const [isSwapped, setIsSwapped] = useState(false);

  // const drawCurrentCard = (view: CardView) => {
  //   if (view === "correct") {
  //     const correctCards = selectCorrectCards(cardsStore)(currentDeckId);
  //     const card = getRandomItem(correctCards);
  //     console.log({ correctCards, card });
  //     setCurrentCard(card);
  //   }
  //   if (view === "incorrect") {
  //     const incorrectCards = selectIncorrectCards(cardsStore)(currentDeckId);
  //     const card = getRandomItem(incorrectCards);
  //     console.log({ incorrectCards, card });
  //     setCurrentCard(card);
  //   }
  //   if (view === "neutral") {
  //     const neutralCards = selectNeutralCards(cardsStore)(currentDeckId);
  //     const card = getRandomItem(neutralCards);
  //     console.log({ neutralCards, card });
  //     setCurrentCard(card);
  //   }
  // };

  // const isEmptyView = () => {
  //   if (currentCard) return false;
  //   const view = getCurrentView(location);
  //   const correctCards = selectCorrectCards(cardsStore)(currentDeckId);
  //   const incorrectCards = selectIncorrectCards(cardsStore)(currentDeckId);
  //   const neutralCards = selectNeutralCards(cardsStore)(currentDeckId);
  //   if (view === "correct" && correctCards.length > 0) {
  //     return false;
  //   }
  //   if (view === "incorrect" && incorrectCards.length > 0) {
  //     return false;
  //   }
  //   if (view === "neutral" && neutralCards.length > 0) {
  //     return false;
  //   }
  //   return true;
  // };

  return {
    progress,
    setProgress,
    isSwapped,
    setIsSwapped,

    // currentCard,
    // drawCurrentCard,
    // currentDeckId,
    // setCurrentDeckId,
    // isEmptyView,
  };
}

// export function dispatchInitialState(data: Word[]) {
//   return new Map<string, Card<Word>>(
//     data.map((item, idx) => {
//       const card: Card<Word> = { id: String(idx), data: item };
//       return [card.id, card];
//     })
//   );
// }

// export function dispatchCardStatus(
//   id: string,
//   status: Extract<CardStatus, "red" | "yellow" | "green">
// ) {
//   return (state: Map<string, Card<Word>>) => {
//     const newState = new Map(state);
//     const item = newState.get(id);
//     if (item == null) throw new Error(`Missing item with id (${id})`);
//     item.status = status;
//     newState.set(item.id, item);
//     return newState;
//   };
// }

type PlayerContextType = ReturnType<typeof usePlayerContext>;

const PlayerContext = createContext<PlayerContextType | null>(null);

export function PlayerContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const value = usePlayerContext();
  return (
    <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>
  );
}

export function usePlayer() {
  const context = useContext(PlayerContext);
  if (context == null)
    throw new Error(
      "useContext(MultipleChoicePlayerContext) must be called from within MultipleChoicePlayerContextProvider component."
    );
  return context;
}

// export function getCurrentView(location: Location<any>): CardView | null {
//   const isPathPololei = location.pathname === "/pololei";
//   const isPathHewa = location.pathname === "/hewa";
//   const isPathKoe = location.pathname === "/koe";
//   if (isPathPololei) return "correct";
//   if (isPathHewa) return "incorrect";
//   if (isPathKoe) return "neutral";
//   return null;
// }
