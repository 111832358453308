import { useEffect } from "react";
import { useGlobal } from "./useGlobal";

export function useTheme() {
  const { theme } = useGlobal();
  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      document.documentElement.classList.remove("light");
      document.body.style.colorScheme = "dark"; // dark scrollbar
    }
    if (theme === "light") {
      document.documentElement.classList.add("light");
      document.documentElement.classList.remove("dark");
      document.body.style.colorScheme = "light"; // light scrollbar
    }
  }, [theme]);
}
