import React, { Component } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { GlobalProvider } from "./hooks/useGlobal";
import { router } from "./pages/Nav/Nav";
import { PlayerContextProvider } from "./hooks/usePlayer";
import { CardsStoreProvider } from "./hooks/useCardsStore";

import Handsontable from "handsontable/base";
import { registerAllModules } from "handsontable/registry";
registerAllModules();

registerAllModules();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export function Providers({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <GlobalProvider>
      <CardsStoreProvider>
        <PlayerContextProvider>{children}</PlayerContextProvider>
      </CardsStoreProvider>
    </GlobalProvider>
  );
}

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
