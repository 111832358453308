import "./Player.css";
import cx from "classnames";
import styles from "./Player.module.scss";
import { usePlayer } from "../hooks/usePlayer";
import { Question } from "./Question";
import { SvgCheckO } from "../assets/svg/check-o";
import { SvgCloseO } from "../assets/svg/close-o";
import { Progress } from "../hooks/usePlayer";
import { useCardsStore } from "../hooks/useCardsStore";
import {
  selectCurrentCorrectCard,
  selectCurrentIncorrectCard,
  selectCurrentNeutralCard,
  voteItemCorrect,
  voteItemIncorrect,
} from "../hooks/useCardsStore.helpers";
import { PlayerToolbar } from "./PlayerToolbar";
import { ProgressBar } from "./ProgressBar";
import { SvgSwap } from "../assets/svg/swap";
import { useState } from "react";
import { useGlobal } from "../hooks/useGlobal";

export type CardView = "correct" | "incorrect" | "neutral";

export function PlayerPage(props: { cardView: CardView }) {
  return (
    <div className={cx(styles.PlayerPage)}>
      <PlayerToolbar />
      {/* <div className="w-full justify-start flex py-3 px-6">
        <ProgressBar />
      </div> */}
      <PlayerBody cardView={props.cardView} />
    </div>
  );
}

export function PlayerBody(props: { cardView: CardView }) {
  const { cardView } = props;
  const { progress, setProgress, isSwapped, setIsSwapped } = usePlayer();
  const { cardsStore, setCardsStore } = useCardsStore();

  const currentCard = (function () {
    if (cardView === "correct") return selectCurrentCorrectCard(cardsStore);
    if (cardView === "incorrect") return selectCurrentIncorrectCard(cardsStore);
    if (cardView === "neutral") return selectCurrentNeutralCard(cardsStore);
    return null;
  })();

  // TODO: fix
  // useEffect(() => {
  //   if (isPathPololei) {
  //     const correctCards = selectCorrectCards(cardsStore)(currentDeckId);
  //     setCurrentCard(getRandomItem(correctCards));
  //   }
  //   if (isPathHewa) {
  //     const incorrectCards = selectIncorrectCards(cardsStore)(currentDeckId);
  //     setCurrentCard(getRandomItem(incorrectCards));
  //   }
  //   if (isPathKoe) {
  //     const neutralCards = selectNeutralCards(cardsStore)(currentDeckId);
  //     setCurrentCard(getRandomItem(neutralCards));
  //   }
  //   console.log("hey");
  // }, [
  //   isPathPololei,
  //   isPathHewa,
  //   isPathKoe,
  //   cardsStore,
  //   currentDeckId,
  //   setCurrentCard,
  // ]);
  // console.log("Location changed!", location.pathname);

  const handlePololeiClick = () => {
    if (progress === "ready" || progress === "failure") {
      setProgress("success");
    } else {
      const cardId = currentCard?.idx;
      if (cardId == null) return;
      setCardsStore(voteItemCorrect(cardId));
      setProgress("ready");
      // const view = getCurrentView(location);
      // if (!view) return;
      // drawCurrentCard(view);
    }
  };

  const handleHewaClick = () => {
    if (progress === "ready" || progress === "success") {
      setProgress("failure");
    } else {
      const cardId = currentCard?.idx;
      if (cardId == null) return;
      setCardsStore(voteItemIncorrect(cardId));
      setProgress("ready");
      // const view = getCurrentView(location);
      // if (!view) return;
      // drawCurrentCard(view);
    }
  };

  // const handleOptionClick = (id: string) => {
  //   if (progress === "ready") {
  //     if (currentOptions == null) return;
  //     const first = currentOptions[0];
  //     if (id === first.id) {
  //       setProgress("success");
  //       setCardMap(dispatchCardStatus(first.id, "green"));
  //     } else {
  //       setProgress("failure");
  //       setCardMap(dispatchCardStatus(first.id, "red"));
  //     }
  //     setSelectedOptionId(id);
  //   } else if (progress === "failure" || progress === "success") {
  //     handleNextButtonClick();
  //   }
  // };

  // const shuffledOptions = useMemo(
  //   () =>
  //     (function () {
  //       const shuffledOptions = [...(currentOptions ?? [])];
  //       shuffle(shuffledOptions);
  //       return shuffledOptions;
  //     })(),
  //   [currentOptions]
  // );

  // const optionButtons = shuffledOptions.map((option) => {
  //   const first = (currentOptions ?? [])[0];
  //   if (first == null) return <></>;

  //   const isCorrect = option.id === first.id;
  //   const isSelected = option.id === selectedOptionId;

  //   const emoji = (function () {
  //     // success
  //     if (isCorrect && (progress === "success" || progress === "failure")) {
  //       return "✅";
  //     }

  //     if (progress === "failure" && isSelected) {
  //       return "❌";
  //     }

  //     // failure

  //     return null;
  //   })();

  //   return (
  //     <Option
  //       id={option.id}
  //       text={option.data.answer ?? ""}
  //       handleOptionClick={handleOptionClick}
  //       emoji={emoji}
  //       progress={progress}
  //       types
  //     />
  //   );
  // });

  // const showNextButton = progress === "success" || progress === "failure";

  const handleNextButtonClick = () => {
    // if (view === "neutral" && redCards.length > 8) {
    //   rollForCat();
    // }
    // drawCards(view);
  };

  const handleSwapClick = () => {
    setIsSwapped((s) => !s);
  };

  // if (currentOptions == null) {
  //   return <StartScreen onClickStart={() => drawCards(view)} />;
  // }

  // const backgroundColor = (function () {
  //   if (progress === "success") return "#244e28";
  //   if (progress === "failure") return "#973939";
  //   return "#3f4a66";
  // })();

  // const option = [...(currentOptions ?? [])][0];
  if (!currentCard) {
    return (
      <div className={cx(styles.Player)}>
        <>No cards to show</>
      </div>
    );
  }

  // if (!currentCard) {
  //   return (
  //     <div className={cx(styles.Player)}>
  //       <button
  //         onClick={() => {
  //           const view = getCurrentView(location);
  //           if (!view) return;
  //           drawCurrentCard(view);
  //         }}
  //       >
  //         Go
  //       </button>
  //     </div>
  //   );
  // }

  const [question, answer] = (function () {
    if (isSwapped)
      return [currentCard?.answer ?? "", currentCard?.question ?? ""];
    return [currentCard?.question ?? "", currentCard?.answer ?? ""];
  })();

  return (
    <>
      <div className={cx(styles.PlayerBody)}>
        <Question question={question} />
        <div>
          <div className="optionButtonsContainer">
            <Option
              // id={option.idx}
              text={answer}
              handleOptionClick={handleHewaClick}
              // emoji={emoji}
              progress={progress}
              type={"no"}
            />
            <Option
              // id={option.id}
              text={answer}
              handleOptionClick={handlePololeiClick}
              // emoji={emoji}
              progress={progress}
              type={"yes"}
            />
          </div>
          {/* <div className="nextButtonContainer"> */}
          {/* {showNextButton ? (
            <>
            <button
            className="actionButton"
            tabIndex={-1}
            onClick={handleNextButtonClick}
            >
            Next
            </button>
            </>
            ) : (
              <></>
            )} */}
          {/* </div> */}
        </div>
      </div>
      {/* <div className="w-full flex justify-end"></div> */}
    </>
  );
}

export function Option(props: {
  // id: string;
  text: string;
  handleOptionClick: () => void;
  // emoji: "✅" | "❌" | null;
  progress: Progress;
  type: "yes" | "no";
}) {
  const { text, progress, handleOptionClick, type } = props;
  const { fontStyle } = useGlobal();

  const style = (function () {
    const none: React.CSSProperties["pointerEvents"] = "none";
    // if (emoji === "✅") {
    //   if (progress === "success") {
    //     return {
    //       background: "#63c069d1",
    //     };
    //   }
    //   return { background: "#76c57be8", pointerEvents: none };
    // }
    // if (emoji === "❌") {
    //   return { background: "#ff9494ed", opacity: 0.7 };
    // }
    // if (progress === "success" || progress === "failure") {
    //   return {
    //     opacity: 0.5,
    //     pointerEvents: none,
    //   };
    // }
    return {};
  })();

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleOptionClick();
  };

  const icon = (function () {
    const svg = (function () {
      if (type === "yes") return <SvgCheckO className={"w-[32px]"} />;
      if (type === "no") return <SvgCloseO className={"w-[32px]"} />;
    })();
    return <div className={"mr-3"}>{svg}</div>;
  })();

  const showText =
    (type === "no" && progress === "failure") ||
    (type === "yes" && progress === "success");

  return (
    <div style={style} className={cx(styles.option)} onClick={handleClick}>
      {icon}
      <div
        style={{
          ...(!showText && {
            visibility: "hidden",
          }),
        }}
        className={cx(styles.optionText, {
          [styles.fontNormal]: fontStyle === "normal",
          [styles.fontRound]: fontStyle === "rounded",
          [styles.fontOld]: fontStyle === "old",
        })}
      >
        {text}
      </div>
    </div>
  );
}
